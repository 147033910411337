import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'bg-header-container': (data) => ({
    '&::after': {
      background: `url(${data?.dotMatrixBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    },
  }),
  'global-service-content-header-cls': (data) => ({
    '&::after': {
      background: `url(${data?.capsulesBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    },
  }),
}));
