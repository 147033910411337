import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
//import LoginPage from '../pages/login';
import GlobalServiceContent from '../components/GlobalServiceContent';

export default function globalServiceContentPage({location ,pageContext, data }) {
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const [user, setUser] = useState({});

  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };


  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulGlobalServicesCategoryPage?.seoEntry}
          siteurl={pageContext?.siteurl}
          bucketName={pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />
        <GlobalServiceContent
          data={data?.contentfulGlobalServicesCategoryPage}
          bucketname={pageContext?.bucketName}
          seviceslug={data?.ContentfulGlobalServicesCategoryPage?.slug}
          countryData={pageContext?.allGlobalServiceData}
          basePath={pageContext.basePath}
          location={location}
        />
      </Layout>
    </>
  );
}

export const query = graphql`
  query globalServiceContentPageTemplate($contentful_id: String, $locale: String) {
    contentfulGlobalServicesCategoryPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      entryTitle
      contentful_id
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          file {
            url
          }
        }
      }
      slug
      solutionName
      solutionType
      dropdownLabel
      headerSectionDecription {
        raw
      }
      headerSectionHeroImage {
        file {
          url
        }
        description
      }
      introSectionImage {
        description
        file {
          url
        }
      }
      quoteImage {
        description
        file {
          url
        }
        description
      }
      headerSectionTitle
      introSectionDescription {
        raw
      }
      introSectionSubHeader
      testimonialMessage {
        raw
      }
      associateDesignation
      associateName
      capsulesBackgroundImage {
        file {
          url
        }
        description
      }
      leftDonutBackgroundImage {
        file {
          url
        }
      }
      rightDonutBackgroundImage {
        file {
          url
        }
      }
      arrowImage {
        file {
          url
        }
        description
        title
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      spotLightsImage {
        description
        file {
          url
        }
        description
      }
      letsTalkBackgroundImage {
        file {
          url
        }
      }
      letsTalkSectionTitle {
        raw
      }
      letsTalkButtonLink {
        label
        link {
          ... on ContentfulInternalLink {
            slug
          }
          ... on ContentfulWebLink {
            url
          }
        }
      }
      spotLightsTitle
      spotLightsDescription {
        raw
      }
      spotLightsTiles {
        backgroundImage {
          file {
            url
          }
          description
        }
        linkOrPdf
        header {
          raw          
        }
        pdfOrDocument {
          file {
            url
          }
        }
        tileLink {
          anchorLink
          slug
        }
      }
      ourServicesSectionHeading
      ourServices {
        ...templateA
        ...templateB
        ...templateC
      }
      sys {
        contentType {
          sys {
            id
            type
          }
        }
      }
    }
  }

  fragment templateA on ContentfulGlobalServicesTemplateA {
    id
    contentful_id
    entryTitle
    serviceHeader
    accordionId
    description {
      raw
    }
    videoComponent {
      ...compVideo
    }
    serviceDescriptionSubService1 {
      ...headerDescription
    }
    serviceDescriptionSubService2 {
      ...headerDescription
    }
    serviceDescriptionSubService3 {
      ...headerDescription
    }
    serviceDescriptionSubService4 {
      ...headerDescription
    }
    servicesImageWithPdfDownloadLink {
      ...ImageWithPdfDownloadLink
    }
    testimonialSectionImage {
      file {
        url
      }
      title
      description
    }
    testimonialMessage {
      raw
    }
    name
    designation
    sys {
      contentType {
        sys {
          id
          type
        }
      }
    }
  }

  fragment templateB on ContentfulGlobalServicesTemplateB {
    id
    contentful_id
    entryTitle
    accordionId
    serviceImageWithPdfDownloadLink {
      downloadDocument {
        file {
          url
        }
      }
      downloadImage {
        file {
          url
        }
        description
      }
      downloadLink
    }
    videoComponent {
      ...compVideo
    }
    serviceHeader
    testimonialSectionImage {
      file {
        url
      }
      title
      description
    }
    testimonialMessage {
      raw
    }
    name
    designation
    image {
      file {
        url
      }
      description
    }
    description {
      raw
    }
    sys {
      contentType {
        sys {
          id
          type
        }
      }
    }
  }

  fragment templateC on ContentfulGlobalServicesTemplateC {
    id
    contentful_id
    entryTitle
    serviceHeader
    accordionId
    sys {
      contentType {
        sys {
          id
          type
        }
      }
    }
  }

  fragment compVideo on ContentfulCustomVideo {
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    entryTitle
    videoComponent {
      entryTitle
      videoType
      videoId
      videoTitle
      dateOfPublishing
    }
  }

  fragment headerDescription on ContentfulComponentHeadingDescription {
    id
    contentful_id
    entryTitle
    headerText
    description {
      raw
    }
    sys {
      contentType {
        sys {
          id
          type
        }
      }
    }
  }

  fragment ImageWithPdfDownloadLink on ContentfulComponentImageWithPdfDownloadLink {
    id
    entryTitle
    contentful_id
    downloadImage {
      file {
        url
      }
      title
      description
    }
    downloadDocument {
      file {
        url
      }
      title
      description
    }
    downloadLink
    sys {
      contentType {
        sys {
          id
          type
        }
      }
    }
  }
`;
