import { makeStyles } from '@material-ui/core';
export const myStyles = makeStyles((theme) => ({
  //* **********************   Videos --Row-  ********************** *//
  root: () => ({
    backgroundColor: 'transparent',
    color: '#000',
    cursor: 'pointer',
    borderRadius: '5px',
    height: '100%',
    display: "flex",
    border:"0px",
    flexDirection: "column",
  }),
  playButton: () => ({
    padding: "0!important",
    '&:focus-visible':{
      outline: '2px solid black',
    }
  }),
  /* **********************   Videos --Row-CARD  ********************** */
  videoTitle: () => ({
    fontWeight: "bold",
    [theme.breakpoints.up('xs')]: {
      fontSize: "24px",
      padding: "8px 8px 0",
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: "18px",
      padding: "10px",
    },
    [theme.breakpoints.up('md')]: {
      fontSize: "22px",
      padding: "8px",
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "20px",
      padding: "20px",
    },
    [theme.breakpoints.up('xl')]: {
      padding: '20px 13px',
      fontSize: '20px'
    }
  }),
  videoPublishingDate: () => ({
    display: 'block',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: "8px"
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: "18px",
      paddingLeft: "10px"
    },
    [theme.breakpoints.up('md')]: {
      fontSize: "20px",
      paddingLeft: "8px"
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "20px",
      paddingLeft: "20px"
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: "13px",
      fontSize: '18px'
    }
  }),
  videoBG: () => ({
    width: '100%',
  }),
  videoImgOverlay: () => ({
    position: 'absolute',
    zIndex: '0',
    display: 'block',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    borderRadius: '0 30px 30px 0',

  }),
  playButtonIcon: () => ({
    position: 'absolute',
    color: '#fff',
    width: '100px!important',
    height: '100px!important'
  }),
  responsiveIframe: () => ({
    border: '0',
    left: '0',
    position: 'absolute',
    top: '0',
    width: '100%',
  }),
  iframeClass: () => ({
    position: 'relative',
    backgroundColor: '#fff',
    overflow: 'hidden',
    paddingTop: '56.25%',
    width: '100%',
    '& iframe': {
      border: '0',
      height: '100%',
      left: '0',
      position: 'absolute',
      top: '0',
      width: '100%',
    }
  }),
//   video popup
popupOverlay:()=>({
    position: 'fixed',
    top: '50%',
    left: '50%',
    paddingBottom: '25px',
    transform: 'translate(0%, -50%)',
    [theme.breakpoints.down('xl')]: {
      width:'70%',
      marginLeft:'-35%'
    },
    [theme.breakpoints.down('lg')]: {
      width:'77%',
      marginLeft:'-38.5%'
    },
    borderRadius: 10,
    backgroundColor: '#fff',
    boxShadow: 24,
    paddingLeft: 20,
    paddingRight: 10,
    transition: "all 1s",
    [theme.breakpoints.down('md')]: {
      overflowY:'scroll',
    }
  }),
  popupCloseIcon:()=>({
    alignSelf:'flex-start',
    marginTop: '10px',
    color: '#000000',
    padding:'0!important',
  }),
  popupImage:()=>({
    borderRadius:'50%'
  }),
}));
