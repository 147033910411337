import React, { useState } from 'react';
import './GlobalServiceContent.scss';
import { myStyles } from './style';
import clsx from 'clsx';
import GlobalServiceContentHeader from './GlobalServiceContentHeader';
import GlobalServiceContentServices from './GlobalServiceContentServices';
import GlobalServiceSpotlight from '../GlobalServiceSpotlight';
import LetsTalk from '../LetsTalk';

const GlobalServiceContent = ({ data, countryData, basePath,location }) => {
  const classes = myStyles(data);
  return (
    <div className="globalservice-content-main">
      <div className={clsx('donuts-left', classes['bg-donuts-left'])}>
      <div className={clsx('donuts-right', classes['bg-donuts-right'])}>
        <GlobalServiceContentHeader data={data} countryData={countryData} basePath={basePath} />
          <GlobalServiceContentServices
            serviceImage={data?.arrowImage}
            serviceTitle={data?.ourServicesSectionHeading}
            ourServices={data?.ourServices}
            location={location}
          />
          <GlobalServiceSpotlight data={data} basePath={basePath} />

          <LetsTalk
            title={data?.letsTalkSectionTitle}
            image={data?.letsTalkBackgroundImage}
            LetsTalkButton={data?.letsTalkButtonLink}
            basePath={basePath}
            LetsTalktitle="GlobalServiceContentLetsTalkSectionTitle"
            LetsTalkBackgroundImage="GlobalServiceContentLetsTalkBackgroundImage"
            ButtonLabel="buttonLabel"
          />
        </div>
      </div>
    </div>
  );
};

export default GlobalServiceContent;
