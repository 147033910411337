import React from 'react';
import './GlobalServiceSpotlight.scss';
import Grid from '@material-ui/core/Grid';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SpotlightTiles from './SpotlightTiles';
import Container from '@material-ui/core/Container';

const GlobalServiceSpotlight = ({ data, basePath }) => {
  return (
    <>
    <Container maxWidth="xl" className='globalcontentspotlight-container'>
      
      <Grid
        item
        container
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="global-service-cls"
        id="spotlights"
      >
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <img
              src={data?.spotLightsImage?.file?.url+'?fm=webp&q=100'}
              alt={data?.spotLightsImage?.description}
              className="plusIcon"
              locId="spotLightsImage"
            />
            <h2 className="heading" locId="spotLightsTitle">
              {data?.spotLightsTitle}
            </h2>
          </Grid>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="row-cls">
            {/* First Row */}
            <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
              <div className="desp" locId="spotLightsDescription">
                {data?.spotLightsDescription?.raw &&
                  documentToReactComponents(JSON.parse(data?.spotLightsDescription?.raw))}
              </div>
            </Grid>
            <Grid item lg={5} md={4} className="xl-hide sm-hide"></Grid>
            <Grid item lg={4} md={6} sm={6} xs={6} className="xl-hide"></Grid>
            {data?.spotLightsTiles?.slice(0, 2)?.map((tileData) => {
              return <SpotlightTiles tileData={tileData} basePath={basePath}></SpotlightTiles>;
            })}
            {/* Second Row */}
            <Grid item xl={3} lg={4} className="lg-hide md-hide sm-hide">
            </Grid>
            {data?.spotLightsTiles?.slice(2, 4)?.map((tileData, index) => {
              return (
                <SpotlightTiles tileData={tileData} index={index} data={data} basePath={basePath}></SpotlightTiles>
              );
            })}
            <Grid item xl={3} lg={4} md={6} sm={6} xs={6}></Grid>
            {/* Third Row */}
            <Grid item xl={6} lg={4} md={6} sm={12} xs={12} className="sm-hide md-hide"></Grid>
            {data?.spotLightsTiles?.slice(4, 6)?.map((tileData) => {
              return <SpotlightTiles tileData={tileData} basePath={basePath}></SpotlightTiles>;
            })}
          </Grid>
        </Grid>
      </Grid>
    </Container>
    </>
  );
};
export default GlobalServiceSpotlight;
