import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { myStyles } from './styles';
import './GlobalServiceContentHeader.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import GroupedDropdown from './../../GroupedDropdown';
import Container from '@material-ui/core/Container';


const GlobalServiceContentHeader = ({ data, countryData, basePath }) => {
  const imageOptimum = '?fm=webp&q=100'
  const classes = myStyles(data);
  return (
    <>
      <Grid
        className={clsx(
          'global-service-content-header-cls',
          classes['global-service-content-header-cls']
        )}
      >
        <Container maxWidth="xl" className='globalcontentheader-container'>
        <Grid container className={clsx('header-container', classes['bg-header-container'])}>
          <Grid item xs={6} sm={6} md={5} lg={5} xl={5} className="header-left-text">
            <Typography variant="h1" className="title" locId="headerSectionTitle">
              {data?.headerSectionTitle}
            </Typography>
            {data?.headerSectionDecription?.raw && (
              <p locId="headerSectionDecription">
                {documentToReactComponents(JSON.parse(data?.headerSectionDecription?.raw))}
              </p>
            )}
          </Grid>

          <Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
            <div className="header-image" locId="headerSectionHeroImage">
              <img
                src={data?.headerSectionHeroImage?.file?.url+imageOptimum}
                alt={data?.headerSectionHeroImage?.description}
              />
            </div>
          </Grid>
        </Grid>
        </Container>
      </Grid>

      <Container maxWidth="xl" className='globalcontentheader-container'>
      <Grid container item className="text-with-dropdown-div">
        <Grid item xs={12} sm={12} md={12} lg={8} xl={7} locid="introSectionDescription">
          {data?.introSectionDescription?.raw &&
            documentToReactComponents(JSON.parse(data?.introSectionDescription?.raw))}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={5}>
          <GroupedDropdown data={data} groupTitleData={countryData} basePath={basePath} />
        </Grid>
      </Grid>
      </Container>


      <Container maxWidth="xl" className='globalcontentheader-container'>
      <Grid container item className="text-with-image-div">
        <Grid item xs={12} sm={12} md={12} lg={7} xl={7} className="left-image">
        <Grid className="globalcontent-intro-Xspace-image">
          <img
            src={data?.introSectionImage?.file?.url+imageOptimum}
            alt={data?.introSectionImage?.description}
            locId="introSectionImage"
          />
          <p locid="introSectionSubHeader">{data?.introSectionSubHeader}</p>
          </Grid>
        </Grid>
        <Grid
          item
          xs={11}
          sm={9}
          md={10}
          lg={5}
          xl={5}
          className={clsx('header-container', classes['bg-header-container'])}
        >
          <Grid className="globalcontent-intro-Xspace right-text-div">
          <img
            src={data?.quoteImage?.file?.url+imageOptimum}
            alt={data?.quoteImage?.description}
            locId="quoteImage"
          />
          {data?.testimonialMessage?.raw && 
              documentToReactComponents(JSON.parse(data?.testimonialMessage?.raw))
          }
          <Typography variant="h2" className="author-name" locId="associateName">
            {data?.associateName}
          </Typography>
          <p className="designation" locId="associateDesignation">
            {data?.associateDesignation}
          </p>
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </>
  );
};

export default GlobalServiceContentHeader;
