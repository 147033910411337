import React from 'react';
import './LetsTalk.scss';
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { myStyles } from './styles';
import { Link } from 'gatsby';
import Container from '@material-ui/core/Container';

export default function LetsTalk({
  title,
  image,
  LetsTalkButton,
  LetsTalktitle,
  LetsTalkBackgroundImage,
  ButtonLabel,
  basePath
}) {
  const richtextOptions = {
    renderText: (text) => {
      return text.split('\\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };
  const classes = myStyles(image);
  const letsTalkTitle = title?.raw;
  return (
      <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="lets-talk-cls">    
        <Grid
          item
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={clsx('letstalk-block', classes['bg-image'])}
          locId={LetsTalkBackgroundImage}
        >
          <div className="overlay">
          <Container maxWidth="xl" className='lets-talk-container'>
            <Grid
              item
              container
              xl={5} lg={6} md={7} sm={7} xs={9}
              className="details"
            >
              <Typography className="title"  locId={LetsTalktitle}>
                {letsTalkTitle
                  ? documentToReactComponents(JSON.parse(letsTalkTitle), richtextOptions)
                  : null}
              </Typography>
              {LetsTalkButton && (
                <div>
                  {LetsTalkButton?.link?.url ? (
                    <Link
                      to={LetsTalkButton?.link?.url ? LetsTalkButton?.link?.url : '#'}
                      tabindex="0"
                      target="_blank"
                    >
                      <Button
                        variant="contained"
                        className="btn-more"
                        tabIndex="-1"
                        locId={ButtonLabel}
                      >
                        {LetsTalkButton?.label}
                      </Button>
                    </Link>
                  ) : (
                    <>
                      <Link
                        to={LetsTalkButton?.link?.slug ? `/${basePath}/${LetsTalkButton?.link?.slug}` : '#'}
                        tabindex="0"
                      >
                        <Button
                          variant="contained"
                          className="btn-more"
                          tabIndex="-1"
                          locId={ButtonLabel}
                        >
                          {LetsTalkButton?.label}
                        </Button>
                      </Link>
                    </>
                  )}
                </div>
              )}
            </Grid>
          </Container>
        </div>        
      </Grid>      
    </Grid>
    
  );
}