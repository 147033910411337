import React, { useState, useRef, useEffect } from 'react';
import './GroupedDropdown.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isElement, map } from 'lodash';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import { navigate } from 'gatsby';

const GroupedDropdown = ({ data, groupTitleData, basePath }) => {

  const [activeMenu] = useState(data?.slug);
  const navigateToSubCategory = (event, basePath) => {
    navigate(`/${basePath}/${event.target.value}`);
  };

  const sortedArray = groupTitleData?.sort((a,b)=> a.orderOfDropDownSolutionType - b.orderOfDropDownSolutionType);
  const array = [];
  sortedArray.forEach((sa)=>{
      if(!array.includes(sa.solutionType) && sa.solutionName){
          array.push(sa.solutionType)
      }
  })

  const groupByCategory = sortedArray.reduce((group, product) => {
    const { solutionType } = product;
    group[solutionType] = group[solutionType] ?? [];
    group[solutionType].push(product);
    if(group[solutionType].length > 1){
      group[solutionType].sort((a,b)=> a.orderOfDropDownSolutionName - b.orderOfDropDownSolutionName)
    }
    return group;
  }, {});

  const buttonEl = useRef(null);
  const registerAriaLabel = () => {
    buttonEl?.current?.querySelectorAll('.MuiSelect-nativeInput').forEach((role) => {
      role.setAttribute('aria-label', 'input-dropdown');
    });
  };
  useEffect(() => {
    registerAriaLabel();
    if (window) {
      window.addEventListener('resize', () => {
        registerAriaLabel();
      });
    }
  }, []);

  return (
    <>
      <div className="dropdown-div">
        <Select
          ref={buttonEl}
          className="navbar-btns"
          value={activeMenu}
          onChange={(e) => navigateToSubCategory(e, basePath)}
          disableUnderline
          IconComponent={ExpandMoreIcon}
          locId="dropdownLabel"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem className="choose-solution">{data?.dropdownLabel}</MenuItem>
          {map(array, (keyName) => [
            <ListSubheader label={keyName} className="Submenu-items">
              <h1 locId="solutionType">{keyName}</h1>
            </ListSubheader>,
            map(
              groupByCategory[keyName],
              (item) =>
                item?.solutionName && (
                  <MenuItem
                    className="global-service-content-menu-items"
                    locId="solutionName"
                    tabIndex="0"
                    value={item?.slug}
                  >
                    {item?.solutionName}
                  </MenuItem>
                )
            ),
          ])}
        </Select>
      </div>
    </>
  );
};

export default GroupedDropdown;
