import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Dialog, DialogContent, Slide, IconButton, Box } from '@material-ui/core';
// import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { myStyles } from './styles';
import clsx from 'clsx';
import VideoPopUp from './../VideoPopUp';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function VideoTiles({
  data,
  rootClass,
  thumbnailClass,
  thumbnailAltText,
  thumbnailOverLay,
}) {
  const classes = myStyles();
  const [open, setOpen] = useState(false);

  const videoId = data?.videoComponent?.videoId;
  const videoType = data?.videoComponent?.videoType;
  //   const entryTitle = data?.entryTitle;
  //   const videoTitle = data?.videoComponent?.videoTitle;
  //  const formatedDateString = data?.publishingDate;
  const qumucloudthumbnail = `https://cartus.qumucloud.com/kulu/` + videoId + `/thumbnail`;
  const youtubethumbnail = `https://img.youtube.com/vi/` + videoId + `/mqdefault.jpg?fm=webp&q=100`;

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Grid>
      <Paper elevation={0} variant="outlined" className={clsx(classes.root, rootClass)}>
        <Button className={classes.playButton} onClick={handleClickOpen}>
          <img
            src={videoType ? qumucloudthumbnail : youtubethumbnail}
            alt={thumbnailAltText ? thumbnailAltText : 'video'}
            className={clsx(classes.videoBG, thumbnailClass)}
          />
          <div className={clsx(classes.videoImgOverlay, thumbnailOverLay)}></div>
          <PlayCircleOutlineIcon
            fontSize="large"
            onClick={handleClickOpen}
            className={classes.playButtonIcon}
          />
        </Button>
        <VideoPopUp videoId={videoId} videoType={videoType} open={open} setOpen={setOpen} />
        {/* <Grid>
         <Paper elevation={0}>
          {videoTitle && (<Typography className="resourcehub-video-title" locid='videoTitle'>{videoTitle}</Typography>)}
          {formatedDateString && (<Typography className="video-publishing-date" locid='videopublishDate'>{formatedDateString}</Typography>)}
         </Paper>
        </Grid>  */}
      </Paper>
    </Grid>
  );
}
