import React from 'react';
import './GlobalServiceSpotlight.scss';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { myStyles } from './style';
import { Link } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
const SpotlightTiles = ({ tileData, index, data, basePath }) => {
  const classes = myStyles(data);
  const handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.target.querySelector('a').click();
    }
  };
  return (
    <>
      <Grid
        item
        xl={3}
        lg={4}
        md={6}
        sm={6}
        xs={6}
        className={index == 0 ? clsx('after-matrix', classes['bg-dot-matrix']) : ''} >
        <div className="thumb-wrap"
          tabIndex="0"
          onKeyPress={(e) => {
            handleEnterKeyPress(e);
          }} >
          <Link
            to={
              tileData?.linkOrPdf
                ? `/${basePath}/${tileData?.tileLink?.slug}`
                : tileData?.pdfOrDocument?.file?.url
            }
            tabindex="-1"
            target={tileData?.linkOrPdf ? '_self' : '_blank'} >
            <img
              src={tileData?.backgroundImage?.file?.url+'?fm=webp&q=100'}
              alt={tileData?.backgroundImage?.description}
              locId="backgroundImage"
            />
            <Typography variant="h2" className="title" locId="title">
            {tileData?.header?.raw &&
                documentToReactComponents(JSON.parse(tileData?.header?.raw))}
            </Typography>
          </Link>
        </div>
      </Grid>
    </>
  );
};

export default SpotlightTiles;
