import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'bg-donuts-left': (data) => ({
    background: `url(${data?.leftDonutBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
  }),
  'bg-donuts-right': (data) => ({
    background: `url(${data?.rightDonutBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
  }),
}));
